exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-components-banner-js": () => import("./../../../src/pages/components/Banner.js" /* webpackChunkName: "component---src-pages-components-banner-js" */),
  "component---src-pages-components-button-js": () => import("./../../../src/pages/components/Button.js" /* webpackChunkName: "component---src-pages-components-button-js" */),
  "component---src-pages-components-cta-section-js": () => import("./../../../src/pages/components/CTASection.js" /* webpackChunkName: "component---src-pages-components-cta-section-js" */),
  "component---src-pages-components-faqs-js": () => import("./../../../src/pages/components/faqs.js" /* webpackChunkName: "component---src-pages-components-faqs-js" */),
  "component---src-pages-components-features-js": () => import("./../../../src/pages/components/Features.js" /* webpackChunkName: "component---src-pages-components-features-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/Header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-provider-section-js": () => import("./../../../src/pages/components/ProviderSection.js" /* webpackChunkName: "component---src-pages-components-provider-section-js" */),
  "component---src-pages-components-why-section-js": () => import("./../../../src/pages/components/WhySection.js" /* webpackChunkName: "component---src-pages-components-why-section-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */)
}

